import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    homeContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        margin: "40px auto 0",
        [theme.breakpoints.up(1280)]: {
            maxWidth: "1280px"
        }
    },
    centerText: {
        textAlign: "center"
    },
    uscoWebsiteContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "50px auto auto"
    }
}));

export default function Homepage() {
    const classes = useStyles();

    return (
        <div className={classes.homeContainer}>
            <h1 className={classes.centerText}>Das Office des USCO</h1>
            <p className={classes.centerText}>Hier werden alle Informationen des USCO verwaltet.</p>
            <div className={classes.uscoWebsiteContainer}>
                <p>Du willst eigentlich auf die USCO Website? Klick&nbsp;
                    <a target="_self" rel="nofollow" href="https://www.usco-online.de">hier</a></p>
            </div>
        </div>
    );
}
