import React, {PropsWithChildren, useContext, useState} from "react";
import TextGroup from "../../components/TextGroup";
import {createStyles, makeStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import TextRow from "../../components/TextRow";
import DialogButton from "../../components/DialogButton";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import BackgroundTextField from "../../components/BackgroundTextField";
import {
    DirectDebitAuthority,
    Gender,
    genderToText,
    Membership,
    membershipToText,
    SailEntitlement
} from "../../model/member/IMember";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {UserRole} from "../../model/IUser";
import IRegistrationData, {MailAddressVerified} from "../../model/member/IRegistrationData";
import ContentCheckbox from "../../components/ContentCheckbox";
import ContentButton from "../../components/ContentButton";
import Text from "../../components/Text";
import {handleDateToIsoString, handleMaxDate} from "../../model/DateHandler";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {StyledTextField} from "../../components/StyledComponents";
import {DatePicker} from "@mui/lab";
import {RegisterServiceApi} from "../../api/MemberServiceApi";
import DialogLoadingButton from "../../components/DialogLoadingButton";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginRight: "0"
    },
    button: {
        marginLeft: "auto",
        marginRight: "10px"
    },
    lastButton: {
        marginLeft: "0",
        marginRight: "0"
    }
}));

interface RegistrationDataDetailProps extends PropsWithChildren<any> {
    data: IRegistrationData;

    onRelease(data: IRegistrationData): void;

    onUpdate(data: IRegistrationData): void;

    onDelete(data: IRegistrationData): void;
}

export default function RegistrationDataDetail(props: RegistrationDataDetailProps) {
    const {data, onRelease, onUpdate, onDelete} = props;
    const classes = useStyles();
    const {user, getToken} = useContext(AuthServiceContext);

    const [pendingResend, setPendingResend] = useState<boolean>(false);

    // model for account area
    const [membership, setMembership] = useState<Membership>(!!data.membership ? data.membership : Membership.NONE);
    // model for personal data area
    const [firstName, setFirstName] = useState<string>(!!data.firstName ? data.firstName : "");
    const [lastName, setLastName] = useState<string>(!!data.lastName ? data.lastName : "");
    const [gender, setGender] = useState<Gender>(!!data.gender ? data.gender : Gender.NONE);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date(Date.parse(data.dateOfBirth)));
    // model for contact area
    const [streetLine, setStreetLine] = useState<string>(!!data.streetLine ? data.streetLine : "");
    const [zipCode, setZipCode] = useState<string>(!!data.zipCode ? data.zipCode : "");
    const [city, setCity] = useState<string>(!!data.city ? data.city : "");
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(!!data.phoneNumber ? data.phoneNumber : undefined);
    const [mobileNumber, setMobileNumber] = useState<string | undefined>(!!data.mobileNumber ? data.mobileNumber : undefined);
    // model for bank account area
    const [accountHolder, setAccountHolder] = useState<string>(!!data.accountHolder ? data.accountHolder : "");
    const [iban, setIban] = useState<string>(!!data.iban ? data.iban : "");
    const [bic, setBic] = useState<string>(!!data.bic ? data.bic : "");
    const [bankName, setBankName] = useState<string>(!!data.bankName ? data.bankName : "");
    // model for entitlement area
    const [directDebitAuthority, setDirectDebitAuthority] = useState<DirectDebitAuthority>(!!data.directDebitAuthority ? data.directDebitAuthority : DirectDebitAuthority.NOT_GRANTED);
    const [sailEntitlement, setSailEntitlement] = useState<SailEntitlement>(!!data.sailEntitlement ? data.sailEntitlement : SailEntitlement.UNAUTHORIZED);
    const [mailAddressVerified] = useState<MailAddressVerified>(!!data.mailAddressVerified ? data.mailAddressVerified : MailAddressVerified.UNPERFORMED);

    const handleDirectDebitAuthorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirectDebitAuthority(event.target.checked ?
            DirectDebitAuthority.GRANTED : DirectDebitAuthority.NOT_GRANTED);
    }

    const handleSailEntitlementAuthorizedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSailEntitlement(event.target.checked ? SailEntitlement.AUTHORIZED : SailEntitlement.UNAUTHORIZED);
    }

    const handleCourceParticipantTemporaryAuthorizedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSailEntitlement(event.target.checked ?
            SailEntitlement.TEMPORARY_AUTHORIZED : SailEntitlement.UNAUTHORIZED);
    }

    const handleAllowedToEdit = (): boolean => {
        return user.isInUserRole(UserRole.ADM_USER);
    }

    const handleRelease = (): void => {
        handleUpdateData();
        onRelease(data);
    }

    const handleSave = (): void => {
        handleUpdateData();
        onUpdate(data);
    }

    const handleDelete = (): void => {
        onDelete(data);
    }

    const handleUpdateData = (): void => {
        // model for account
        data.membership = membership;
        // model for personal data
        data.firstName = firstName;
        data.lastName = lastName;
        data.gender = gender;
        data.dateOfBirth = handleDateToIsoString(dateOfBirth);
        // model for contact
        data.streetLine = streetLine;
        data.zipCode = zipCode;
        data.city = city;
        data.phoneNumber = !!phoneNumber ? phoneNumber : "";
        data.mobileNumber = !!mobileNumber ? mobileNumber : "";
        // model for bank account
        data.accountHolder = accountHolder;
        data.iban = iban;
        data.bic = bic;
        data.bankName = bankName;
        // model for entitlement
        data.directDebitAuthority = directDebitAuthority;
        data.sailEntitlement = sailEntitlement;
    }

    const handleModified = (): boolean => {
        return (membership !== data.membership)
            || (firstName !== data.firstName)
            || (lastName !== data.lastName)
            || (gender !== data.gender)
            || (handleDateToIsoString(dateOfBirth) !== data.dateOfBirth)
            || (streetLine !== data.streetLine)
            || (zipCode !== data.zipCode)
            || (city !== data.city)
            || (phoneNumber !== data.phoneNumber)
            || (mobileNumber !== data.mobileNumber)
            || (accountHolder !== data.accountHolder)
            || (iban !== data.iban)
            || (bankName !== data.bankName)
            || (directDebitAuthority !== data.directDebitAuthority)
            || (sailEntitlement !== data.sailEntitlement)
    }

    const handleSailEntitlementAuthorized = (): boolean => {
        return SailEntitlement.AUTHORIZED === sailEntitlement
    }

    const handleCourseParticipantTemporaryAuthorized = (): boolean => {
        return SailEntitlement.TEMPORARY_AUTHORIZED === sailEntitlement;
    }

    const handleMailVerifiedPerformed = (): boolean => {
        return MailAddressVerified.PERFORMED === mailAddressVerified;
    }

    const handleResendMailVerification = (): void => {
        setPendingResend(true);
        RegisterServiceApi.resendVerifyMailAddress(data, getToken())
            .then((response) => {
                console.info(response)
            })
            .catch((error) => console.error("unexpected error: " + error.message))
            .finally(() => setPendingResend(false));
    }

    return (
        <div key={data.username} className={classes.container}>
            <TextGroup label="Konto">
                <TextRow>
                    <BackgroundDropdown id="registration-data-detail-membership-label"
                                        label="Art der Mitgliedschaft"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit() ? membership : membershipToText(membership)}
                                        defaultValue={data.membership}
                                        nonValue={Membership.NONE}
                                        errorMessage="Art der Mitgliedschaft nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setMembership(event.target.value);
                                        }}>
                        <MenuItem value={Membership.NONE} disabled>{membershipToText(Membership.NONE)}</MenuItem>
                        <MenuItem value={Membership.SINGLE}>{membershipToText(Membership.SINGLE)}</MenuItem>
                        <MenuItem value={Membership.JUNIOR}>{membershipToText(Membership.JUNIOR)}</MenuItem>
                        <MenuItem value={Membership.STUDENT}>{membershipToText(Membership.STUDENT)}</MenuItem>
                        <MenuItem value={Membership.FAMILY}>{membershipToText(Membership.FAMILY)}</MenuItem>
                    </BackgroundDropdown>
                    {data.courseMember && (<Text label="Hinweis"
                                                 value="Bereits zu einem Kurs angemeldet"
                                                 className={classes.rightColumn}/>)}
                </TextRow>
                <TextRow last>
                    <Text className={classes.leftColumn}
                          label="Nutzername"
                          value={data.username}/>
                    <Text className={classes.rightColumn}
                          label="E-Mail-Adresse"
                          value={data.mailAddress}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Persönliche Daten">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Vorname"
                                         defaultValue={data.firstName}
                                         onChange={(event: any) => {
                                             setFirstName(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Nachname"
                                         defaultValue={data.lastName}
                                         onChange={(event: any) => {
                                             setLastName(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundDropdown id="registration-data-detail-gender-label"
                                        label="Geschlecht"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit() ? gender : genderToText(gender)}
                                        defaultValue={data.gender}
                                        nonValue={Gender.NONE}
                                        errorMessage="Geschlecht nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setGender(event.target.value);
                                        }}>
                        <MenuItem value={Gender.NONE} disabled>{genderToText(Gender.NONE)}</MenuItem>
                        <MenuItem value={Gender.MALE}>{genderToText(Gender.MALE)}</MenuItem>
                        <MenuItem value={Gender.FEMALE}>{genderToText(Gender.FEMALE)}</MenuItem>
                        <MenuItem value={Gender.DEVICE}>{genderToText(Gender.DEVICE)}</MenuItem>
                    </BackgroundDropdown>
                    <DatePicker
                        label="Geburtsdatum"
                        openTo="year"
                        views={["year", "day"]}
                        maxDate={handleMaxDate()}
                        value={dateOfBirth}
                        onChange={setDateOfBirth}
                        onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                            console.info(reason, value);
                        }}
                        renderInput={(params) =>
                            <StyledTextField {...params}
                                             className={classes.rightColumn}
                                             required
                                             label="Geburtsdatum"
                                             placeholder="Geburtsdatum"
                            />}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                    />
                </TextRow>
            </TextGroup>
            <TextGroup label="Anschrift und Kontakt">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Straße und Hausnummer"
                                         defaultValue={data.streetLine}
                                         onChange={(event: any) => {
                                             setStreetLine(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.middleColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Postleitzahl"
                                         defaultValue={data.zipCode}
                                         onChange={(event: any) => {
                                             setZipCode(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Stadt"
                                         defaultValue={data.city}
                                         onChange={(event: any) => {
                                             setCity(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow last>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Festnetznummer"
                                         defaultValue={data.phoneNumber}
                                         onChange={(event: any) => {
                                             setPhoneNumber(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Mobilnummer"
                                         defaultValue={data.mobileNumber}
                                         onChange={(event: any) => {
                                             setMobileNumber(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            <TextGroup label="Bankverbindung">
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Kontoinhaber"
                                         defaultValue={data.accountHolder}
                                         onChange={(event: any) => {
                                             setAccountHolder(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="IBAN"
                                         defaultValue={data.iban}
                                         onChange={(event: any) => {
                                             setIban(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextField className={classes.leftColumn}
                                         editable={handleAllowedToEdit()}
                                         label="BIC"
                                         defaultValue={data.bic}
                                         onChange={(event: any) => {
                                             setBic(event.target.value);
                                         }}/>
                    <BackgroundTextField className={classes.rightColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Bankinstitut"
                                         defaultValue={data.bankName}
                                         onChange={(event: any) => {
                                             setBankName(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <ContentCheckbox className={classes.oneColumn}
                                     checked={DirectDebitAuthority.GRANTED === directDebitAuthority}
                                     label="Bankverbindung geprüft / Einzug erfolgreich"
                                     editable={handleAllowedToEdit()}
                                     onChange={handleDirectDebitAuthorityChange}/>
                </TextRow>
                <TextRow>
                    <ContentCheckbox className={classes.oneColumn}
                                     checked={handleSailEntitlementAuthorized()}
                                     label="Segelnachweis geprüft / vorgelegt"
                                     editable={!handleCourseParticipantTemporaryAuthorized() && handleAllowedToEdit()}
                                     onChange={handleSailEntitlementAuthorizedChanged}/>
                </TextRow>
                <TextRow>
                    <ContentCheckbox className={classes.oneColumn}
                                     checked={handleCourseParticipantTemporaryAuthorized()}
                                     label="Ist Kursmitglied und darf vorübergehend Boote buchen"
                                     editable={!handleSailEntitlementAuthorized() && handleAllowedToEdit()}
                                     onChange={handleCourceParticipantTemporaryAuthorizedChanged}/>
                </TextRow>
                <TextRow last>
                    <ContentCheckbox className={classes.oneColumn}
                                     checked={handleMailVerifiedPerformed()}
                                     label="E-Mail-Adresse wurde überprüft"
                                     editable={false}
                                     onChange={() => {
                                     }}/>
                </TextRow>
            </TextGroup>
            <div>Registriert am: {data.registrationTimestamp}</div>
            {handleAllowedToEdit() && (<div className={classes.buttonContainer}>
                {!handleMailVerifiedPerformed() && (
                    <DialogLoadingButton label="Mail-Verifizierung senden"
                                         title="Mail Verifizierung erneut schicken?"
                                         variant="secondary"
                                         actionLabel="Senden"
                                         pending={pendingResend}
                                         onActionClick={handleResendMailVerification}
                                         buttonStyle={{marginLeft: "0", marginRight: "0"}}/>)}
                <DialogButton label="Ablehnen"
                              title="Neue Mitgliedsanfrage wirklich ablehnen?"
                              variant="secondary"
                              actionLabel="Ablehnen"
                              onActionClick={handleDelete}
                              buttonClassName={classes.button}/>
                {handleMailVerifiedPerformed() && (<>
                    {DirectDebitAuthority.GRANTED === directDebitAuthority && !handleModified() ?
                        (<DialogButton label="Freigeben"
                                       title="Neue Mitgliedsanfrage wirklich freigeben?"
                                       variant="primary"
                                       actionLabel="Freigeben"
                                       onActionClick={handleRelease}
                                       buttonClassName={classes.lastButton}/>) :
                        (<ContentButton className={classes.lastButton}
                                        variant="primary"
                                        disabled={!handleModified()}
                                        onClick={handleSave}>Speichern</ContentButton>)}
                </>)}
            </div>)}
        </div>
    );
}
