import {MenuItem} from "@mui/material";
import React, {PropsWithChildren, useContext, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import {StyledTextField} from "../../components/StyledComponents";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import TextGroup from "../../components/TextGroup";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {DatePicker} from "@mui/lab";
import {handleDateToIsoString, handleIsoStringToDate} from "../../model/DateHandler";
import IMessage, {
    MessagePriority,
    messagePriorityToText,
    MessageType,
    messageTypeToText
} from "../../model/news/IMessage";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import {UserRole} from "../../model/IUser";
import Text from "../../components/Text";
import BackgroundTextField from "../../components/BackgroundTextField";
import DialogButton from "../../components/DialogButton";
import ContentLoadingButton from "../../components/ContentLoadingButton";

const useStyles = makeStyles(() => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        backgroundColor: "#E8E7E8"
    },
    oneColumn: {
        width: "100%"
    },
    twoColumn: {
        width: "calc(66%)",
        marginRight: "10px"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginLeft: "0",
        marginRight: "0"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginLeft: "0",
        marginRight: "0"
    },
    card: {
        marginTop: "0.75rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        backgroundColor: "rgb(232, 231, 232)"
    },
    header: {
        fontSize: "16px",
        color: "#023553",
        marginBottom: "20px"
    }
}));

interface MessageDetailProps extends PropsWithChildren<any> {
    message: IMessage;
    loading: boolean;

    onUpdate(message: IMessage): void;

    onDelete(message: IMessage): void;
}

export default function MessageDetail(props: MessageDetailProps) {

    const {message, loading, onUpdate, onDelete} = props;
    const {user} = useContext(AuthServiceContext);
    const classes = useStyles();

    // model
    const [type, setType] = useState<MessageType>(message.type);
    const [title, setTitle] = useState<string>(message.title);
    const [startDate, setStartDate] = useState<Date | null>(new Date(Date.parse(message.date)));
    const [expireDate, setExpireDate] = useState<Date | null>(handleIsoStringToDate(message.expiredAtDate));
    const [content, setContent] = useState<string>(message.content);
    const [author, setAuthor] = useState<string>(message.author);
    const [priority, setPriority] = useState<MessagePriority>(message.priority);
    const [recipient, setRecipient] = useState<string | undefined>(message.recipient);

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_NEWSLETTER) && message.type !== MessageType.NEWSLETTER;
    }

    const handleSave = () => {
        message.type = type;
        message.title = title;
        message.date = handleDateToIsoString(startDate);
        message.expiredAtDate = expireDate !== null ? handleDateToIsoString(expireDate) : undefined;
        message.content = content;
        message.author = author;
        message.priority = priority;
        message.recipient = recipient;
        onUpdate(message);
    }

    const handleDelete = () => {
        onDelete(message);
    }

    const handleModified = (): boolean => {
        return (type !== message.type)
            || (title !== message.title)
            || (handleDateToIsoString(startDate) !== message.date)
            || (handleDateToIsoString(expireDate) !== message.expiredAtDate)
            || (content !== message.content)
            || (author !== message.author)
            || (priority !== message.priority)
            || (recipient !== message.recipient);
    }

    return (
        <div key={message.number} className={classes.container}>
            <TextGroup label="Die Nachricht">
                <TextRow>
                    <Text className={classes.leftColumn}
                          label="Nachrichtennummer" value={message.number}/>
                    <BackgroundDropdown id="new-message-type-label"
                                        label="Typ"
                                        className={classes.rightColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit() ? type : messageTypeToText(message.type)}
                                        defaultValue={type}
                                        nonValue={MessageType.NONE}
                                        errorMessage="Type nicht ausgewählt."
                                        validated={false}
                                        required={true}
                                        onChange={(event: any) => {
                                            setType(event.target.value);
                                        }}>
                        <MenuItem value={MessageType.NONE} disabled>{messageTypeToText(MessageType.NONE)}</MenuItem>
                        <MenuItem value={MessageType.MESSAGE}>{messageTypeToText(MessageType.MESSAGE)}</MenuItem>
                        <MenuItem value={MessageType.NEWSLETTER}>{messageTypeToText(MessageType.NEWSLETTER)}</MenuItem>
                    </BackgroundDropdown>
                </TextRow>
            </TextGroup>
            <TextGroup label="Inhalt der Nachricht">
                <TextRow>
                    <BackgroundTextField className={classes.oneColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Titel"
                                         defaultValue={title}
                                         onChange={(event: any) => {
                                             setTitle(event.target.value);
                                         }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextArea className={classes.oneColumn}
                                        editable={handleAllowedToEdit()}
                                        label="Inhalt"
                                        rows={5}
                                        variant="outlined"
                                        value={content}
                                        onChange={(event: any) => {
                                            setContent(event.target.value);
                                        }}/>
                </TextRow>
                <TextRow>
                    <BackgroundTextField className={classes.oneColumn}
                                         editable={handleAllowedToEdit()}
                                         label="Autor"
                                         defaultValue={author}
                                         onChange={(event: any) => {
                                             setAuthor(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>
            {type === MessageType.NEWSLETTER && (<TextGroup label="Auf Nachricht antworten">
                <TextRow>
                    <BackgroundTextField className={classes.oneColumn}
                                         editable={handleAllowedToEdit()}
                                         label="E-Mail-Adresse"
                                         defaultValue={recipient}
                                         onChange={(event: any) => {
                                             setRecipient(event.target.value);
                                         }}/>
                </TextRow>
            </TextGroup>)}
            <TextGroup label="Sichtbarkeit der Nachricht">
                <TextRow>
                    <BackgroundDropdown id="new-message-priority-label"
                                        label="Priorität"
                                        className={classes.leftColumn}
                                        editable={handleAllowedToEdit()}
                                        useValue={true}
                                        value={handleAllowedToEdit()
                                            ? priority : messagePriorityToText(message.priority)}
                                        nonValue={MessagePriority.NONE}
                                        onChange={(event: any) => {
                                            setPriority(event.target.value);
                                        }}>
                        <MenuItem value={MessagePriority.NONE}>{messagePriorityToText(MessagePriority.NONE)}</MenuItem>
                        <MenuItem value={MessagePriority.HERO}>{messagePriorityToText(MessagePriority.HERO)}</MenuItem>
                    </BackgroundDropdown>
                    <DatePicker
                        label={type === MessageType.NEWSLETTER ? "Gesendet am" : "Anzeigen ab"}
                        openTo="day"
                        views={["year", "day"]}
                        value={startDate}
                        disabled={!handleAllowedToEdit()}
                        onChange={setStartDate}
                        onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                            console.info(reason, value);
                        }}
                        renderInput={(params) =>
                            <StyledTextField {...params}
                                             className={type === MessageType.NEWSLETTER
                                                 ? classes.rightColumn : classes.middleColumn}
                                             required
                                             label={type === MessageType.NEWSLETTER ? "Gesendet am" : "Anzeigen ab"}
                                             placeholder="Anzeigen ab"
                            />}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                    />
                    {type !== MessageType.NEWSLETTER && (<DatePicker
                        label="Anzeigen bis"
                        openTo="day"
                        views={["year", "day"]}
                        value={expireDate}
                        disabled={!handleAllowedToEdit()}
                        onChange={setExpireDate}
                        onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                            console.info(reason, value);
                        }}
                        renderInput={(params) =>
                            <StyledTextField {...params}
                                             className={classes.rightColumn}
                                             label="Anzeigen bis"
                                             placeholder="Anzeigen bis"
                            />}
                        inputFormat="dd.MM.yyyy"
                        mask="__.__.____"
                    />)}
                </TextRow>
            </TextGroup>
            {handleAllowedToEdit() && (
                <div className={classes.buttonContainer}>
                    <DialogButton label="Löschen"
                                  title="Nachricht wirklich löschen?"
                                  variant="secondary"
                                  actionLabel="Löschen"
                                  onActionClick={handleDelete}
                                  buttonClassName={classes.button}/>
                    <ContentLoadingButton className={classes.lastButton}
                                          variant="primary"
                                          pending={loading}
                                          disabled={!handleModified()}
                                          onClick={handleSave}>Speichern</ContentLoadingButton>
                </div>
            )}
        </div>
    );
}
