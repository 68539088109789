import React, {useContext, useEffect, useState} from "react";
import {BoatServiceApi} from "../../api/BoatServiceApi";
import IBoat, {locationText} from "../../model/IBoat";
import DataTable, {DataHeaderColumn, DataTableRow} from "../../components/DataTable";
import BoatDetail from "./BoatDetail";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import NewBoat from "./NewBoat";
import {createStyles, makeStyles} from "@mui/styles";
import {UserRole} from "../../model/IUser";
import ContentButton from "../../components/ContentButton";
import ContentContainer from "../../components/ContentContainer";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        position: "relative"
    },
    newBoatButton: {
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        },
        position: "absolute",
        top: "-82px",
        right: "0",
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: "0"
    }
}));


export default function Boats() {
    const [loading, setLoading] = useState<boolean>(false);
    const [boats, setBoats] = useState<IBoat[]>([]);
    const [newBoat, setNewBoat] = useState<boolean>(false);
    const [selectedBoat, setSelectedBoat] = useState<string | undefined>(undefined);

    const {user, getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        BoatServiceApi.getBoats(getToken())
            .then(response => {
                const boats = response.sort((a: IBoat, b: IBoat) => {
                    return Number(a.number).valueOf() - Number(b.number).valueOf();
                });
                setBoats(boats);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleCreate = (boat: IBoat) => {
        setLoading(true);
        BoatServiceApi.createBoat(boat, getToken())
            .then(storedBoat => {
                console.info("created boat:", storedBoat);
                const newBoats = [...boats, storedBoat];
                setBoats(newBoats);
                setNewBoat(false);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleUpdate = (boat: IBoat) => {
        setLoading(true);
        BoatServiceApi.updateBoat(boat, getToken())
            .then((updatedBoat: IBoat) => {
                handleChange(updatedBoat);
                console.info("updated boat:", updatedBoat);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (boat: IBoat) => {
        setLoading(true);
        BoatServiceApi.deleteBoat(boat, getToken())
            .then(() => {
                const filteredBoats = boats.filter(function (b) {
                    return b.number !== boat.number;
                });
                setSelectedBoat(undefined);
                setBoats(filteredBoats);
                console.info("deleted boat:", boat);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message, error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChange = (updatedBoat: IBoat) => {
        setSelectedBoat(undefined);
        const boatIndex = boats.findIndex(boat => boat.number === updatedBoat.number);
        boats.splice(boatIndex, 1, updatedBoat);
        setBoats([...boats]);
        setSelectedBoat(updatedBoat.number);
    }

    const handleSelection = (selectedId: string) => {
        setSelectedBoat(selectedId);
    }

    const handleAdd = () => {
        setNewBoat(true);
    }

    const handleCancel = () => {
        setNewBoat(false);
    }

    const handleAllowedToCreate = () => {
        return !newBoat && handleAllowedToEdit();
    }

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_BOAT);
    }

    const headerColumns: DataHeaderColumn<IBoat>[] = [
        {value: "name", sortable: true, node: "Name"},
        {value: "type", sortable: true, node: "Typ"},
        {value: "location", sortable: true, node: "Liegestelle"},
        {value: "outOfOrder", sortable: false, node: "Gesperrt"}
    ];

    const uniqueKeyColumn = "number";

    const dataRows: DataTableRow<IBoat>[] = boats.map((boat) => ({
        value: boat,
        nodes: [
            <>{boat.name}</>,
            <>{boat.type}</>,
            <>{locationText(boat.location)}</>,
            <>{boat.outOfOrder && boat.outOfOrder.broken ? "Ja" : "Nein"}</>
        ],
        detail:
            <BoatDetail boat={boat}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onChange={handleChange}/>
    }));

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Unsere Boote</h1>
            {newBoat && <NewBoat onSave={handleCreate} onCancel={handleCancel}/>}
            <div className={classes.content}>
                {handleAllowedToCreate() && (<ContentButton className={classes.newBoatButton}
                                                            onClick={handleAdd}>Neu</ContentButton>)}
                <DataTable headerColumns={headerColumns}
                           dataRows={dataRows}
                           uniqueKeyColumn={uniqueKeyColumn}
                           selected={selectedBoat}
                           onSelection={handleSelection}/>
            </div>
        </ContentContainer>
    );
}
