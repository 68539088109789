import React, {useContext, useEffect, useState} from "react";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import {MessageServiceApi} from "../../api/ContentServiceApi";
import IMessage, {messagePriorityToText, messageTypeToText} from "../../model/news/IMessage";
import {createStyles, makeStyles} from "@mui/styles";
import ContentContainer from "../../components/ContentContainer";
import ContentButton from "../../components/ContentButton";
import DataTable, {DataHeaderColumn, DataOrder, DataTableRow} from "../../components/DataTable";
import {UserRole} from "../../model/IUser";
import NewMessage from "./NewMessage";
import MessageDetail from "./MessageDetail";
import {handleDateToLocaleString} from "../../model/DateHandler";

const useStyles = makeStyles(() => createStyles({
    headline: {
        textAlign: "center",
        marginBottom: "40px"
    },
    content: {
        position: "relative"
    },
    newButton: {
        color: "#ffffff",
        backgroundColor: "#02A1A0",
        fontFamily: "Roboto Mono",
        fontSize: "14px",
        lineHeight: "28px",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        },
        position: "absolute",
        top: "-82px",
        right: "0",
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: "0"
    }
}));

export default function Messages() {

    const [loading, setLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [newMessage, setNewMessage] = useState<boolean>(false);
    const [selectedMessage, setSelectedMessage] = useState<string | undefined>(undefined);

    const {user, getToken} = useContext(AuthServiceContext);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        MessageServiceApi.getNewsletters(getToken())
            .then((response: IMessage[]) => {
                setMessages(response);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getToken]);

    const handleCreate = (message: IMessage) => {
        setLoading(true);
        MessageServiceApi.createNewsletter(message, getToken())
            .then(storedMessage => {
                console.info("created message", message);
                const newMessage = [...messages, storedMessage];
                setMessages(newMessage);
                setNewMessage(false);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleUpdate = (message: IMessage) => {
        setLoading(true);
        MessageServiceApi.updateNewsletter(message, getToken())
            .then((updatedMessage: IMessage) => {
                const messageIndex = messages.findIndex(message => message.number === updatedMessage.number);
                messages.splice(messageIndex, 1, updatedMessage);
                setMessages(messages);
                setSelectedMessage(undefined);
                console.info("updated message:", message);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (message: IMessage) => {
        setLoading(true);
        MessageServiceApi.deleteNewsletter(message, getToken())
            .then(() => {
                const filteredMessages = messages.filter(messageToFilter => messageToFilter.number !== message.number);
                setMessages(filteredMessages);
                setSelectedMessage(undefined);
                console.info("delete message:", message);
            })
            .catch((error) => {
                console.error("unexpected error: " + error.message);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSelection = (selectedId: string) => {
        setSelectedMessage(selectedId);
    }

    const handleAdd = () => {
        setNewMessage(true);
    }

    const handleCancel = () => {
        setNewMessage(false);
    }

    const handleAllowedToCreate = () => {
        return !newMessage && handleAllowedToEdit();
    }

    const handleAllowedToEdit = () => {
        return user.isInUserRole(UserRole.ADM_NEWSLETTER);
    }

    const headerColumns: DataHeaderColumn<IMessage>[] = [
        {value: "title", sortable: true, node: "Title"},
        {value: "priority", sortable: true, node: "Priorität"},
        {value: "date", sortable: true, node: "Anzeigen ab"},
        {value: "expiredAtDate", sortable: true, node: "Anzeigen bis"},
        {value: "author", sortable: true, node: "Autor"},
        {value: "type", sortable: false, node: "Type"}
    ];

    const uniqueKeyColumn = "number";

    const defaultOrder: DataOrder<IMessage> = {
        headerColumn: headerColumns.filter(headerColumn => headerColumn.value === "date")[0],
        direction: "desc"
    };

    const dataRows: DataTableRow<IMessage>[] = messages.map((message: IMessage) => ({
        value: message,
        nodes: [
            <>{message.title}</>,
            <>{messagePriorityToText(message.priority)}</>,
            <>{handleDateToLocaleString(message.date)}</>,
            <>{handleDateToLocaleString(message.expiredAtDate)}</>,
            <>{message.author}</>,
            <>{messageTypeToText(message.type)}</>
        ],
        detail:
            <MessageDetail message={message}
                           loading={loading}
                           onUpdate={handleUpdate}
                           onDelete={handleDelete}/>
    }));

    return (
        <ContentContainer process={loading}>
            <h1 className={classes.headline}>Verwaltung der Nachrichten</h1>
            {newMessage && <NewMessage loading={loading}
                                       onSave={handleCreate}
                                       onCancel={handleCancel}/>}
            <div className={classes.content}>
                {handleAllowedToCreate() && (<ContentButton className={classes.newButton}
                                                            onClick={handleAdd}>Neu</ContentButton>)}
                <DataTable headerColumns={headerColumns}
                           dataRows={dataRows}
                           uniqueKeyColumn={uniqueKeyColumn}
                           defaultOrder={defaultOrder}
                           selected={selectedMessage}
                           onSelection={handleSelection}/>
            </div>
        </ContentContainer>
    );

}
