import {MenuItem, Paper} from "@mui/material";
import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import TextRow from "../../components/TextRow";
import ContentButton from "../../components/ContentButton";
import {StyledTextField} from "../../components/StyledComponents";
import BackgroundTextArea from "../../components/BackgroundTextArea";
import TextGroup from "../../components/TextGroup";
import {DateValidationError} from "@mui/lab/internal/pickers/date-utils";
import {ParseableDate} from "@mui/lab/internal/pickers/constants/prop-types";
import {DatePicker} from "@mui/lab";
import {handleDateToIsoString} from "../../model/DateHandler";
import IMessage, {
    MessagePriority,
    messagePriorityToText,
    MessageType,
    messageTypeToText
} from "../../model/news/IMessage";
import BackgroundDropdown from "../../components/BackgroundDropdown";
import {AuthServiceContext} from "../../provider/AuthServiceProvider";
import Text from "../../components/Text";
import BackgroundTextField from "../../components/BackgroundTextField";
import DialogLoadingButton from "../../components/DialogLoadingButton";

const useStyles = makeStyles(() => createStyles({
    oneColumn: {
        width: "100%"
    },
    twoColumn: {
        width: "calc(66%)",
        marginRight: "10px"
    },
    leftColumn: {
        width: "calc(33% - 10px)",
        marginRight: "10px"
    },
    middleColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "auto",
        marginRight: "auto"
    },
    rightColumn: {
        width: "calc(33% - 10px)",
        marginLeft: "10px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginLeft: "auto"
    },
    button: {
        marginRight: "10px"
    },
    lastButton: {
        marginRight: "0"
    },
    card: {
        marginTop: "0.75rem",
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        backgroundColor: "rgb(232, 231, 232)"
    },
    header: {
        fontSize: "16px",
        color: "#023553",
        marginBottom: "20px"
    }
}));

interface NewMessageProps extends PropsWithChildren<any> {
    loading: boolean;

    onSave(newMessage: IMessage): void;

    onCancel(): void;
}

export default function NewMessage(props: NewMessageProps) {

    const {loading, onSave, onCancel} = props;
    const classes = useStyles();
    const {user} = useContext(AuthServiceContext);

    // model
    const [type, setType] = useState<MessageType>(MessageType.NEWSLETTER);
    const [title, setTitle] = useState<string>();
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [expireDate, setExpireDate] = useState<Date | null>(null);
    const [content, setContent] = useState<string>();
    const [author, setAuthor] = useState<string>("");
    const [priority, setPriority] = useState<MessagePriority>(MessagePriority.NONE);
    const [recipient, setRecipient] = useState<string>();

    // dropdown content
    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        setAuthor(user.firstName + " " + user.lastName);
    }, [user]);

    const handleSave = () => {
        if (handleIsValidate()) {
            setValidated(false);
            console.info("save");
            onSave({
                number: "",
                type: type ? type : MessageType.NONE,
                title: title ? title : "",
                content: content ? content : "",
                author: author ? author : "",
                priority: priority ? priority : MessagePriority.NONE,
                date: handleDateToIsoString(startDate),
                expiredAtDate: type === MessageType.NEWSLETTER
                    ? handleDateToIsoString(startDate) : handleDateToIsoString(expireDate),
                recipient: type === MessageType.NEWSLETTER ? recipient : undefined
            });
        } else {
            setValidated(true);
            console.info("message to save not valid");
        }
    }

    const handleCancel = () => {
        onCancel();
    }

    const handleIsValidate = (): boolean => {
        return type !== MessageType.NONE
            && (!!title && title.length > 0)
            && (!!content && content.length > 0)
            && (!!author && author.length > 0)
            && startDate !== null;
    }

    return (<>

            <Paper elevation={3} className={classes.card}>
                <TextGroup label="Neue Nachricht anlegen">
                    <TextRow>
                        <BackgroundDropdown id="new-message-type-label"
                                            label="Typ"
                                            className={classes.leftColumn}
                                            useValue={true}
                                            value={type}
                                            nonValue={MessageType.NONE}
                                            errorMessage="Type nicht ausgewählt."
                                            validated={validated}
                                            required={true}
                                            onChange={(event: any) => {
                                                setType(event.target.value);
                                            }}>
                            <MenuItem value={MessageType.NONE} disabled>{messageTypeToText(MessageType.NONE)}</MenuItem>
                            <MenuItem value={MessageType.MESSAGE}>{messageTypeToText(MessageType.MESSAGE)}</MenuItem>
                            <MenuItem
                                value={MessageType.NEWSLETTER}>{messageTypeToText(MessageType.NEWSLETTER)}</MenuItem>
                        </BackgroundDropdown>
                    </TextRow>
                </TextGroup>
                <TextGroup label="Inhalt der Nachricht">
                    <TextRow>
                        <StyledTextField className={classes.oneColumn}
                                         required
                                         error={validated && (!title || title.length === 0)}
                                         helperText={validated && (!title || title.length === 0) ? "Der Titel fehlt." : null}
                                         variant="outlined"
                                         label="Titel"
                                         placeholder="Der Titel der Vereinsarbeit"
                                         onChange={(event: any) => {
                                             setTitle(event.target.value);
                                         }}/>
                    </TextRow>
                    <TextRow>
                        <BackgroundTextArea className={classes.oneColumn}
                                            required
                                            error={validated && (!content || content.length === 0)}
                                            helperText={validated && (!content || content.length === 0) ? "Der Beschreibung fehlt." : null}
                                            rows={5}
                                            variant="outlined"
                                            label="Inhalt"
                                            placeholder="Der Inhalt der Nachricht"
                                            onChange={(event: any) => {
                                                setContent(event.target.value);
                                            }}/>
                    </TextRow>
                    <TextRow>
                        <Text label="Autor"
                              value={author}
                              required
                              className={classes.oneColumn}/>
                    </TextRow>
                </TextGroup>
                {type === MessageType.NEWSLETTER && (<TextGroup label="Auf Nachricht antworten">
                    <TextRow>
                        <BackgroundTextField className={classes.oneColumn}
                                             label="E-Mail-Adresse"
                                             defaultValue={recipient}
                                             onChange={(event: any) => {
                                                 setRecipient(event.target.value);
                                             }}/>
                    </TextRow>
                </TextGroup>)}
                {type === MessageType.MESSAGE && (<TextGroup label="Sichtbarkeit der Nachricht">
                    <TextRow>
                        <BackgroundDropdown id="new-message-priority-label"
                                            label="Priorität"
                                            className={classes.leftColumn}
                                            useValue={true}
                                            value={priority}
                                            nonValue={MessagePriority.NONE}
                                            onChange={(event: any) => {
                                                setPriority(event.target.value);
                                            }}>
                            <MenuItem
                                value={MessagePriority.NONE}>{messagePriorityToText(MessagePriority.NONE)}</MenuItem>
                            <MenuItem
                                value={MessagePriority.HERO}>{messagePriorityToText(MessagePriority.HERO)}</MenuItem>
                        </BackgroundDropdown>
                        <DatePicker
                            label="Anzeigen ab"
                            openTo="day"
                            views={["year", "day"]}
                            value={startDate}
                            onChange={setStartDate}
                            onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                                console.info(reason, value);
                            }}
                            renderInput={(params) =>
                                <StyledTextField {...params}
                                                 className={classes.middleColumn}
                                                 required
                                                 error={validated && (!startDate)}
                                                 helperText={validated && (!startDate) ? "Das Anzeigen ab Datum fehlt." : null}
                                                 label="Anzeigen ab"
                                                 placeholder="Anzeigen ab"
                                />}
                            inputFormat="dd.MM.yyyy"
                            mask="__.__.____"
                        />
                        <DatePicker
                            label="Anzeigen bis"
                            openTo="day"
                            views={["year", "day"]}
                            value={expireDate}
                            onChange={setExpireDate}
                            onError={(reason: DateValidationError, value: ParseableDate<Date>) => {
                                console.info(reason, value);
                            }}
                            renderInput={(params) =>
                                <StyledTextField {...params}
                                                 className={classes.rightColumn}
                                                 label="Anzeigen bis"
                                                 placeholder="Anzeigen bis"
                                />}
                            inputFormat="dd.MM.yyyy"
                            mask="__.__.____"
                        />
                    </TextRow>
                </TextGroup>)}
                <div className={classes.buttonContainer}>
                    <ContentButton className={classes.button}
                                   variant="secondary"
                                   onClick={handleCancel}>Abbrechen</ContentButton>
                    <DialogLoadingButton label={type === MessageType.NEWSLETTER ? "Versenden" : "Veröffentlichen"}
                                         title={`Nachricht wirklich ${type === MessageType.NEWSLETTER
                                             ? "versenden?" : "veröffentlichen?"}`}
                                         variant="primary"
                                         pending={loading}
                                         actionLabel={type === MessageType.NEWSLETTER ? "Versenden" : "Veröffentlichen"}
                                         onActionClick={handleSave}
                                         buttonClassName={classes.lastButton}/>
                </div>
            </Paper></>
    );
}
