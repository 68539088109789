export enum UserRole {
    ADM_USER = "ADM_USER",
    ADM_ASSOCIATION_WORKING = "ADM_ASSOCIATION_WORKING",
    ADM_NEWSLETTER = "ADM_NEWSLETTER",
    ADM_BOAT = "ADM_BOAT",
    EDIT_BOAT = "EDIT_BOAT",
    EDIT_CONTENT = "EDIT_CONTENT",
    EDIT_COURSE = "EDIT_COURSE",
    MULTI_BOOKING = "MULTI_BOOKING"
}

export function roleToText(role: UserRole) {
    switch (role) {
        case UserRole.ADM_USER:
            return "Mitglieder verwalten";
        case UserRole.ADM_ASSOCIATION_WORKING:
            return "Vereinsarbeiten verwalten";
        case UserRole.ADM_NEWSLETTER:
            return "Nachrichten verwalten";
        case UserRole.ADM_BOAT:
            return "Boote verwalten";
        case UserRole.EDIT_BOAT:
            return "Boot als Bootsmann/Bootsfrau bearbeiten";
        case UserRole.EDIT_CONTENT:
            return "Inhalten bearbeiten";
        case UserRole.EDIT_COURSE:
            return "Kurse bearbeiten";
        case UserRole.MULTI_BOOKING:
            return "Mehrfaches buchen";
        default:
            return "";
    }
}

export enum UserGroup {
    ADMINISTRATOR = "ADMINISTRATOR",
    BOARD_MEMBER = "BOARD_MEMBER",
    BOATSWAIN = "BOATSWAIN",
    CLUB_MEMBER = "CLUB_MEMBER",
    INSTRUCTOR = "INSTRUCTOR",
    ORGANIZER = "ORGANIZER"
}

export function groupToText(group: UserGroup) {
    switch (group) {
        case UserGroup.ADMINISTRATOR:
            return "Administrator";
        case UserGroup.BOARD_MEMBER:
            return "Vorstand";
        case UserGroup.BOATSWAIN:
            return "Bootsmann/Bootsfrau";
        case UserGroup.CLUB_MEMBER:
            return "Vereinsmitglied";
        case UserGroup.INSTRUCTOR:
            return "Trainer:in";
        case UserGroup.ORGANIZER:
            return "Organisator:in";
        default:
            return "";
    }
}

export default interface IUser {
    userId: string;
    username: string;
    mailAddress: string;
    firstName?: string;
    lastName?: string;
    roles: UserRole[];
    groups: UserGroup[];
    isInUserRole(role: UserRole): boolean;
    isInUserGroup(group: UserGroup): boolean;
    authenticated: boolean;
    expired: boolean;
}
