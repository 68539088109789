import {DirectDebitAuthority, Gender, Membership, SailEntitlement} from "./IMember";

export enum MailAddressVerified {
    PERFORMED = "PERFORMED",
    UNPERFORMED = "UNPERFORMED"
}

export default interface IRegistrationData {
    username: string;
    membership: Membership;
    mailAddress: string;
    courseMember: boolean;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    streetLine: string;
    zipCode: string;
    city: string;
    phoneNumber: string;
    mobileNumber: string;
    accountHolder: string;
    iban: string;
    bic: string;
    bankName: string;
    directDebitAuthority?: DirectDebitAuthority;
    sailEntitlement?: SailEntitlement;
    mailAddressVerified?: MailAddressVerified;
    registrationTimestamp: string;
}
